// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-angsten-js": () => import("./../../../src/pages/angsten.js" /* webpackChunkName: "component---src-pages-angsten-js" */),
  "component---src-pages-behandelingen-js": () => import("./../../../src/pages/behandelingen.js" /* webpackChunkName: "component---src-pages-behandelingen-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-404-js": () => import("./../../../src/pages/old-404.js" /* webpackChunkName: "component---src-pages-old-404-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-therapeuten-js": () => import("./../../../src/pages/therapeuten.js" /* webpackChunkName: "component---src-pages-therapeuten-js" */),
  "component---src-templates-fear-page-js": () => import("./../../../src/templates/fear-page.js" /* webpackChunkName: "component---src-templates-fear-page-js" */),
  "component---src-templates-plain-page-js": () => import("./../../../src/templates/plain-page.js" /* webpackChunkName: "component---src-templates-plain-page-js" */),
  "component---src-templates-therapist-page-js": () => import("./../../../src/templates/therapist-page.js" /* webpackChunkName: "component---src-templates-therapist-page-js" */),
  "component---src-templates-training-page-js": () => import("./../../../src/templates/training-page.js" /* webpackChunkName: "component---src-templates-training-page-js" */)
}

